import { AppShell } from "@mantine/core";
import Header from "../layout/header";

export function ProfileLayout({ children }: { children: any }) {
  return (
    <AppShell
      padding={0}
      styles={(_theme) => ({
        main: { backgroundColor: "transparent" },
      })}>
      <Header />
      <AppShell.Main style={{ background: "red !important" }}>{children}</AppShell.Main>
    </AppShell>
  );
}
