import { Box, Button, Divider, Grid, LoadingOverlay, Modal, Paper, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconExclamationCircle, IconNotes, IconShieldCheckFilled } from "@tabler/icons-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthApi } from "../../../../apis";
import Minilayout from "../../../../components/layout/mini-layout";
import { MeForm } from "../../../../components/profile-layout/me-form";
import { Changepassword } from "../../../../components/settings/change-password";
import { ChangePin } from "../../../../components/settings/change-pin";
import { IAuth } from "../../../../interfaces/IAuth";
import { authMe } from "../../../../store/auth";

const MainInfo = () => {
  const { user } = useSelector((state: { auth: IAuth | any }) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<any>("PROFILE");
  const [passwordModal, setPasswordModal] = useState<any>([]);
  const navigate = useNavigate();
  const { theme } = useStyles();

  const [modalType, setModalType] = useState({
    type: "",
    message: "",
    visible: false,
  });

  const handleDialogOk = () => {
    setModalType({ type: "", message: "", visible: false });
    navigate("/auth/profile/dan");
  };
  const previousSearch = useRef("");
  const location = useLocation();
  const dispatch = useDispatch();

  const me = useCallback(async () => {
    const auth = await AuthApi.me();
    dispatch(authMe(auth));
  }, [dispatch]);

  const danVerify = useCallback(
    async (search: string) => {
      const authResult = new URLSearchParams(search);

      if (authResult.get("code") && authResult.get("expires")) {
        try {
          setLoading(true);
          await AuthApi.danVerify({
            code: authResult.get("code")!,
            state: authResult.get("state") || "",
            expires: authResult.get("expires")!,
          });

          await new Promise((resolve) => setTimeout(resolve, 1500));
          setModalType({ type: "success", message: "Таны бүртгэл амжилттай баталгаажлаа", visible: true });
          me(); // Now `me` is stable because it's in useCallback
        } catch (error: any) {
          setModalType({ type: "error", message: error.message || "Бүртгэл баталгаажаагүй дахин оролдоно уу!", visible: true });
        } finally {
          setLoading(false);
        }
      }
    },
    [me],
  );

  useEffect(() => {
    const verifyDan = async () => {
      const authResult = new URLSearchParams(location.search);

      if (location.search !== previousSearch.current && authResult.get("code") && authResult.get("expires")) {
        previousSearch.current = location.search;
        await danVerify(location.search);
      }
    };

    verifyDan();
  }, [location.search, danVerify]);

  return (
    <Grid.Col span={{ sm: 12, md: 6, lg: 7, xl: 9 }}>
      <Box
        h="100%"
        sx={(theme) => ({
          border: `1px solid  ${theme.colors.gray[4]}`,
          borderRadius: theme.radius.md,
          backgroundColor: theme.white,
          padding: theme.spacing.lg,
        })}>
        <Tabs variant="pills" value={activeTab} onChange={(value: string | null) => setActiveTab(value)} h="100%">
          <Tabs.List>
            <Tabs.Tab leftSection={<IconNotes color={activeTab === "PROFILE" ? "white" : "gray"} />} value="PROFILE">
              <Text c={activeTab === "PROFILE" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
            </Tabs.Tab>
          </Tabs.List>

          <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="lg" label="ҮНДСЭН МЭДЭЭЛЭЛ" />

          <Tabs.Panel value="PROFILE">
            <LoadingOverlay visible={loading} />
            <MeForm changePassword={setPasswordModal} isDanVerified={user?.isDanVerified} />
          </Tabs.Panel>
        </Tabs>
      </Box>
      <Modal centered withCloseButton={false} opened={passwordModal[0]} onClose={() => setPasswordModal([])} padding={0}>
        <Paper>
          <Minilayout title={passwordModal[1] === "PIN" ? (user?.hasPin ? "Пин код өөрчлөх" : "Пин код үүсгэх") : "Нууц үг солих"}>
            {passwordModal[1] === "PIN" ? <ChangePin onClose={() => setPasswordModal([])} /> : <Changepassword onClose={() => setPasswordModal([])} />}
          </Minilayout>
        </Paper>
      </Modal>
      <Modal
        centered
        withCloseButton={false}
        opened={modalType.visible}
        size="sm"
        closeOnClickOutside={false}
        onClose={() => setModalType({ type: "", message: "", visible: false })}>
        <Box sx={(theme) => ({ padding: theme.spacing.md, display: "flex", flexDirection: "column", gap: 10, alignItems: "center" })}>
          {modalType.type === "success" ? (
            <IconShieldCheckFilled color={theme.colors.green[6]} size={56} />
          ) : modalType.type === "error" ? (
            <IconExclamationCircle color={theme.colors.red[6]} size={56} />
          ) : null}
          <Text c={theme.colors.dark[5]} size="md">
            {modalType.message}
          </Text>
          <Button variant="outline" onClick={() => handleDialogOk()} color={modalType.type === "success" ? "green" : "red"}>
            Ойлголоо
          </Button>
        </Box>
      </Modal>
    </Grid.Col>
  );
};

const useStyles = createStyles(() => ({}));

export default MainInfo;
