import { Badge, Box, Divider, Flex, Grid, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconId, IconMail, IconPhone, IconUser } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { AuthApi } from "../../../../apis";
import { ImageUploadMe } from "../../../../components/upload/image-upload-me";
import { IAuth } from "../../../../interfaces/IAuth";
import { message } from "../../../../utils/message";

const inventationStatus = (val: string) => {
  switch (val) {
    case "DRAFT": {
      return (
        <Badge color="gray" size="md" radius="sm" variant="filled">
          Түр төлөв
        </Badge>
      );
    }
    case "VERIFIED": {
      return (
        <Badge color="violet" size="md" radius="sm" variant="filled">
          Бүртгэсэн
        </Badge>
      );
    }
    case "ACTIVE": {
      return (
        <Badge color="green" size="md" radius="sm" variant="filled">
          Идэвхтэй
        </Badge>
      );
    }
    case "IN_ACTIVE": {
      return (
        <Badge color="red" size="md" radius="sm" variant="filled">
          Идэвхгүй
        </Badge>
      );
    }

    case "INVITED": {
      return (
        <Badge color="cyan" size="md" radius="sm" variant="filled">
          Урьсан
        </Badge>
      );
    }
    default: {
      return (
        <Badge color="gray" size="md" radius="sm" variant="filled">
          Хоосон
        </Badge>
      );
    }
  }
};

const booleanStatus = (val: boolean) => {
  switch (val) {
    case true: {
      return (
        <Badge color="indigo" size="md" radius="sm" variant="filled">
          Тийм
        </Badge>
      );
    }
    case false: {
      return (
        <Badge color="gray" size="md" radius="sm" variant="filled">
          Үгүй
        </Badge>
      );
    }
    default: {
      return (
        <Badge color="gray" size="md" radius="sm" variant="outline">
          Хоосон
        </Badge>
      );
    }
  }
};

const UserInfo = () => {
  const { user } = useSelector((state: { auth: IAuth | any }) => state.auth);
  const { theme } = useStyles();
  const changeAvatar = async (e: any) => {
    try {
      await AuthApi.updateAvatar({ avatar: e?.url });
      message.success("Хэрэглэгчийн зураг амжилттай солигдлоо");
    } catch (error: any) {
      message.error(error.message || "Алдаа гарлаа");
    }
  };

  const getUserDisplayName = (user: any) => {
    if (!user) return "-";
    const lastNameInitial = user.lastName ? user.lastName[0] + "." : "";
    return `${lastNameInitial}${user.firstName || ""}`;
  };

  return (
    <Grid.Col span={{ sm: 12, md: 6, lg: 4, xl: 3 }}>
      <Box
        h="100%"
        sx={(theme) => ({
          border: `1px solid  ${theme.colors.gray[4]}`,
          borderRadius: theme.radius.md,
          backgroundColor: theme.white,
          padding: theme.spacing.lg,
        })}>
        <Flex align="center" direction="column" gap="md" justify="center">
          <ImageUploadMe value={user?.avatar} radius={100} size={85} onChange={(e) => changeAvatar(e)} />
          <Text fw={600} size="xl" c="violet" tt="capitalize">
            {getUserDisplayName(user)}
          </Text>
          <Text size="sm" c="gray">
            {user?.role?.name || ""}
          </Text>

          <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 500 } }} label="Холбоо барих" />

          <Flex direction="column" gap="xs" justify="start" align="start">
            <Flex gap="sm" align="center">
              <IconMail color={theme.colors.violet[6]} />
              <Text fw={500} c="gray" size="sm">
                {user?.email || "-"}
              </Text>
            </Flex>
            <Flex gap="sm" align="center">
              <IconPhone color={theme.colors.violet[6]} />
              <Text fw={500} c="gray" size="sm">
                {user?.phone || "-"}
              </Text>
            </Flex>
            <Flex gap="sm" justify="start" align="start" w="100%">
              <IconUser color={theme.colors.violet[6]} />
              <Text fw={500} c="gray">
                {user?.departmentUnit?.name || "-"}
              </Text>
            </Flex>
            <Flex gap="sm" justify="start" align="start" w="100%">
              <IconId color={theme.colors.violet[6]} />
              <Text fw={500} c="gray">
                {user?.employeeUnit?.name || "-"}
              </Text>
            </Flex>
          </Flex>
          <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="Төлөв" />
          <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
            <Text fw={500} c="gray">
              Хэрэглэгчийн төлөв:
            </Text>
            {inventationStatus(user?.userStatus)}
            <Text fw={500} c="gray">
              И-мэйл баталгаажилт:
            </Text>
            {booleanStatus(user?.isEmailVerified)}
            <Text fw={500} c="gray">
              Утас баталгаажилт:
            </Text>
            {booleanStatus(user?.isPhoneVerified)}
            <Text fw={500} c="gray">
              ДАН систем баталгаажилт:
            </Text>
            {booleanStatus(user?.isDanVerified)}
          </Flex>
        </Flex>
      </Box>
    </Grid.Col>
  );
};

export default UserInfo;

const useStyles = createStyles(() => ({}));
