import { Button, LoadingOverlay, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { OtpApi } from "../../apis";
import { Form } from "../../components/form";
import { authChange } from "../../store/auth";
import { message } from "../../utils/message";
import { OtpField } from "../form/otp-field";

type IForm = {
  otp: string | undefined;
  isCreate: boolean;
};

const schema = yup.object({
  otp: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!"),
});

export function OtpVerifyForm({ type }: { type: "phone" | "create" }) {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [data] = React.useState<IForm>({
    otp: undefined,
    isCreate: type === "create",
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);

    try {
      if (id && type === "create") {
        const res = await OtpApi.registerVerify({ verifyId: id!, otpCode: values?.otp as string });
        dispatch(authChange(res as any));
        navigate("/verify/phone");
      } else if (id && type === "phone") {
        const res = await OtpApi.verify({ otpMethod: "FORGOT", otpCode: values?.otp as string });
        dispatch(authChange(res));
      }
      setLoading(false);
      message.success("Баталгаажуулалт амжилттай");
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <div className={classes.card}>
            <LoadingOverlay visible={loading} />
            <Text className={classes.title}>Баталгаажуулах код</Text>
            <Text ta="center" className={classes.bodyText}>
              {type === "create"
                ? "Таны бүртгүүлсэн и-мэйл хаягруу явуулсан 6 оронтой тоог доорх талбарт оруулж мэдээллээ баталгаажуулна уу"
                : "Таны бүртгэлтэй дугаар-руу явуулсан 6 оронтой тоог доорх талбарт оруулж мэдээллээ баталгаажуулна уу"}
            </Text>
            <OtpField numInputs={type === "create" ? 6 : 6} name="otp" size="md" />
            <Button loading={loading} type="submit" variant="gradient" radius={5} size="md" gradient={{ from: "#AD61FF", to: "#7165FA" }}>
              Баталгаажуулах
            </Button>
          </div>
        );
      }}
    </Form>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    padding: theme.spacing.xl,
    backgroundColor: "#fff",
    borderRadius: theme.radius.lg,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 520,
    gap: theme.spacing.md,
    [`@media (max-width: 575px)`]: {
      width: "100%",
    },
  },
  title: {
    fontSize: theme.fontSizes.xl,
    fontWeight: 700,
    color: "black",
  },
  bodyText: {
    fontSize: theme.fontSizes.md,
    color: "black",
  },
}));
