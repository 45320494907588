import { Box, Button, Flex, Space } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { AuthApi, OtpApi } from "../apis";
import { authChange } from "../store/auth";
import { message } from "../utils/message";
import { CreatePassword } from "./auth/create-password";
import { Form } from "./form";
import { TextField } from "./form/text-field";

const schema = yup.object({
  username: yup.string().required("Заавал бөглөнө!").trim(),
  email: yup.string().email("Та и-мэйлээ зөв оруулна уу!").required("Заавал бөглөнө!").trim().lowercase(),
});

type IForm = {
  username: undefined | string;
  email: undefined | string;
};

const ForgotForm = ({ setLoading }: { setLoading: any }) => {
  const { classes } = useStyles();
  const [isCreatePassword, setIsCreatePassword] = useState<boolean>(false);
  const [isSent, setIsSent] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const [sessionScope, setSessionScope] = useState<string>("");
  const [data] = React.useState<IForm>({
    username: undefined,
    email: undefined,
  });

  const [pinData] = React.useState<{
    otpCode: undefined | string | null | number;
  }>({
    otpCode: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);

    try {
      const res = await AuthApi.forgot(values);
      setLoading(false);
      message.success(res?.message);
      setSessionScope(res?.sessionScope || "");
      dispatch(authChange(res as any));

      setIsSent(true);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  const onSubmitVerify = async (values: any) => {
    setLoading(true);

    try {
      const res = await OtpApi.verify({ otpMethod: sessionScope, otpCode: values.otpCode });
      setLoading(false);
      message.success(res?.message);
      dispatch(authChange(res as any));
      setIsCreatePassword(true);
      setLoading(false);

      setIsSent(true);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message);
    }
  };

  if (isCreatePassword) {
    return (
      <>
        <Space h={"lg"} />
        <CreatePassword />
      </>
    );
  } else
    return (
      <Box mx="auto" className={classes.container}>
        {isSent ? (
          <Flex direction="column" gap="md">
            <Box mt={50}>
              <Form
                onSubmit={onSubmitVerify}
                initialValues={pinData}
                validationSchema={yup.object({
                  otpCode: yup
                    .string()
                    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
                    .required("Заавал бөглөнө!"),
                })}>
                {() => {
                  return (
                    <Box className={classes.formWrapper}>
                      <TextField
                        required
                        label="Нэг удаагийн код оо оруулна уу:"
                        name="otpCode"
                        variant="unstyled"
                        placeholder="Код оо"
                        size="md"
                        className={classes.input}
                      />
                      <Button variant="gradient" gradient={{ from: "red", to: "#051139" }} size="md" w="100%" type="submit">
                        Шалгах
                      </Button>
                    </Box>
                  );
                }}
              </Form>
            </Box>
          </Flex>
        ) : (
          <Form onSubmit={onSubmit} initialValues={data} validationSchema={schema}>
            {() => {
              return (
                <Box className={classes.formWrapper}>
                  <TextField
                    label="Хэрэглэгчийн нэрээ оруулна уу:"
                    name="username"
                    variant="unstyled"
                    placeholder="Нэр..."
                    size="md"
                    className={classes.input}
                  />
                  <TextField
                    label="Хэрэглэгчийн email оруулна уу:"
                    name="email"
                    variant="unstyled"
                    placeholder="ma***@mail.com"
                    size="md"
                    className={classes.input}
                  />
                  <Button variant="gradient" gradient={{ from: "red", to: "#051139" }} size="md" w="100%" type="submit">
                    Нууц үг сэргээх
                  </Button>
                  <div className={classes.infoText}>Таны и-мэйл хаягт нууц үг сэргээх линк очно.</div>
                </Box>
              );
            }}
          </Form>
        )}
      </Box>
    );
};

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
  },
  labelText: {
    color: theme.white,
    fontWeight: 400,
    fontSize: 16,
  },
  input: {
    input: {
      backgroundColor: "#00000050",
      paddingInline: 10,
      "&:focus-within": {
        color: theme.white,
      },
      color: theme.white,
    },
    label: {
      color: theme.white,
      marginBottom: 10,
    },
    color: theme.white,
    alignItems: "center",
  },
  infoText: {
    color: "white",
    textAlign: "center",
  },
  button: {
    width: "100%",
    height: 50,
    borderRadius: 0,
    fontSize: 16,
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    marginTop: 20,
  },
}));

export default ForgotForm;
