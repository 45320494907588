import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const firebaseToken = async (token: any) => {
  const res = await httpRequest.post("/2fa/auth/fcm/token", token);
  return res;
};

export const list = async ({ offset }: any) => {
  return httpRequest.get("/2fa/auth/fcm/list", { offset });
};

export const seen = async (id: string) => {
  return httpRequest.put(`/2fa/auth/fcm/seen/${id}`);
};
