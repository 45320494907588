import { AppShell, Avatar, Flex, Group, Menu, Modal, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useColorScheme } from "@mantine/hooks";
import { IconChevronDown, IconKey, IconLogin, IconLogout, IconUserCircle } from "@tabler/icons-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AuthApi } from "../../apis";
import { ReactComponent as EraLogo } from "../../assets/white-logo.svg";
import { IAuth } from "../../interfaces/IAuth";
import { authChange, authMe } from "../../store/auth";
import { message } from "../../utils/message";
import { renderName } from "../../utils/render-name";
import Notifications from "../notifications";
import { useScreenDetector } from "../screen-detector";
import { Changepassword } from "../settings/change-password";
import { ChangePin } from "../settings/change-pin";
import Minilayout from "./mini-layout";

export const Header = () => {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [action, setAction] = React.useState<any>([]);
  const { isMobile } = useScreenDetector();

  const { user, sessionScope } = useSelector((state: { auth: IAuth }) => state.auth);
  const location = useLocation();

  const LogOut = async () => {
    try {
      await AuthApi.logout();

      const removeToken = { accessToken: null };
      const removeUser = {};
      dispatch(authChange(removeToken as any));
      dispatch(authMe(removeUser as any));
      navigate("/login");
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  return (
    <>
      <AppShell.Header className={classes.header} style={{ background: location.pathname === "/auth/profile/dan" ? "#010614" : "" }}>
        {!isMobile && (
          <Link to={user && user?.id ? "/" : "/login"}>
            <div className={classes.row}>
              <EraLogo className={classes.logo} />
              <div className={classes.subTitle}>payment</div>
            </div>
          </Link>
        )}
        {user && user?.id && sessionScope === "AUTHORIZED" ? (
          <Flex gap="sm" align="center">
            <Notifications />
            <Menu width={200} position="bottom-end" transitionProps={{ duration: 150, exitDuration: 150, transition: "pop-top-right" }}>
              <Menu.Target>
                <UnstyledButton className={classes.user}>
                  <Group>
                    <Avatar src={user?.avatar ?? "https://static.vecteezy.com/packs/media/vectors/term-bg-1-3d6355ab.jpg"} radius="xl" />
                    <div style={{ flex: 1 }}>
                      <Text c="white" size="sm" fw={500} tt={"capitalize"}>
                        {renderName(user)}
                      </Text>
                      <Text c="white" size="xs">
                        {user?.email ?? "-"}
                      </Text>
                    </div>
                    <IconChevronDown color={theme.colors.violet[6]} size={14} stroke={1.5} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item onClick={() => navigate("/auth/profile/dan")} leftSection={<IconUserCircle size={20} color={theme.colors.indigo[6]} stroke={1.5} />}>
                  Миний мэдээлэл
                </Menu.Item>
                <Menu.Item onClick={() => setAction([true, "pin"])} leftSection={<IconKey size={20} color={theme.colors.yellow[6]} stroke={1.5} />}>
                  Пин код
                </Menu.Item>
                <Menu.Item onClick={() => setAction([true, "password"])} leftSection={<IconLogin size={20} color={theme.colors.blue[6]} stroke={1.5} />}>
                  Нууц үг
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item color="red" onClick={() => LogOut()} leftSection={<IconLogout size={20} stroke={1.5} />}>
                  Системээс гарах
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        ) : null}
      </AppShell.Header>

      <Modal
        opened={action[0]}
        onClose={() => setAction([])}
        centered
        withCloseButton={false}
        styles={{
          body: {
            padding: 0,
          },
        }}>
        {action[1] === "pin" && (
          <Minilayout title="Пин код солих">
            <ChangePin onClose={() => setAction([])} />
          </Minilayout>
        )}
        {action[1] === "password" && (
          <Minilayout title="Нууц үг солих">
            <Changepassword onClose={() => setAction([])} />
          </Minilayout>
        )}
      </Modal>
    </>
  );
};

export default Header;

const useStyles = createStyles((theme) => ({
  header: {
    zIndex: 10,
    display: "flex",
    justifyContent: "space-between",
    justifyItems: "center",
    border: 0,
    backgroundColor: "#00000090",
    alignItems: "center",
    height: 70,
  },
  logo: {
    height: "50px",
  },
  subTitle: {
    display: "flex",
    fontSize: 20,
    fontWeight: 600,
    color: "red",
    marginBottom: "-5px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: "15px",
    alignItems: "end",
    paddingLeft: "20px",
  },
  user: {
    height: "100%",
    color: theme.black,
    paddingLeft: theme.spacing.xs,
    paddingRight: theme.spacing.xs,
    "&:hover": {
      backgroundColor: "#ffffff10",
    },
  },
  userActive: {
    backgroundColor: useColorScheme() === "dark" ? theme.colors.dark[8] : theme.white,
  },
}));
