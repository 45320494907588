import { Grid } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import PageLayout from "../../components/layout/page-layout";
import { ProfileLayout } from "../../components/profile-layout/index";
import MainInfo from "./components/main-info";
import UserInfo from "./components/user-info";

const Profile = () => {
  const { theme } = useStyles();
  const breadcrumbs = useBreadcrumb();

  return (
    <ProfileLayout>
      <PageLayout title="Миний мэдээлэл" subTitle={"Миний мэдээлэл"} breadcrumb={breadcrumbs} bgColor={theme.colors.gray[0]}>
        <Grid gutter="md">
          <UserInfo />
          <MainInfo />
        </Grid>
      </PageLayout>
    </ProfileLayout>
  );
};

export default Profile;

const useStyles = createStyles(() => ({}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Миний мэдээлэл",
  },
];
