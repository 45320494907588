import { Button, LoadingOverlay, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { PasswordApi } from "../../apis";
import { authChange } from "../../store/auth";
import { message } from "../../utils/message";
import { Form } from "../form";
import { PasswordField } from "../form/password-field";

type IForm = {
  password: string | undefined;
  checkPassword: string | undefined;
};

const schema = yup.object({
  password: yup.string().min(6, "Багадаа 6 тэмдэгттэй байх ёстой").max(16, "Ихдээ 16 тэмдэгттэй байх ёстой").required("Нууц үг оруулана уу"),
  checkPassword: yup
    .string()
    .min(6, "Багадаа 6 тэмдэгттэй байх ёстой")
    .max(16, "Ихдээ 16 тэмдэгттэй байх ёстой")
    .oneOf([yup.ref("password")], "Нууц үг таарахгүй байна")
    .required("Нууц үг оруулана уу"),
});

export function CreatePassword() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data] = React.useState<IForm>({
    password: undefined,
    checkPassword: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      await PasswordApi.change({ password: values?.password as string });
      dispatch(authChange());
      setLoading(false);
      message.success("Амжилттай баталгаажлаа. Танд заавар и-мэйл очсоныг шалгана уу.");
      navigate("/login");
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {() => {
        return (
          <div className={classes.card}>
            <LoadingOverlay visible={loading} />
            <Text className={classes.title}>Нууц үг үүсгэх</Text>
            <Text ta="center" className={classes.bodyText}>
              Таны үүсгэсэн нууц үг дараа таныг нэвтрэхэд шаардагдах тул өөрийн мэдээллийн аюулгүй байдлын төлөө хэнтэй ч хуваалцахгүй байна уу
            </Text>
            <PasswordField radius={4} size="md" className={classes.input} name="password" placeholder="Нууц үг" />
            <PasswordField radius={4} size="md" className={classes.input} name="checkPassword" placeholder="Давтан нууц үг" />
            <Button loading={loading} type="submit" variant="gradient" radius={4} size={"md"} gradient={{ from: "#AD61FF", to: "#7165FA" }}>
              Баталгаажуулах
            </Button>
          </div>
        );
      }}
    </Form>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 420,
    maxWidth: 624,
    borderRadius: 10,
    gap: 20,
    position: "relative",
    padding: 20,
    [`@media (max-width: 575px)`]: {
      width: "100%",
      height: "auto",
      maxWidth: "100%",
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: "black",
  },
  bodyText: {
    fontSize: 16,
    fontWeight: 400,
    color: "black",
  },
  input: {
    height: 60,
    width: "60%",
    [`@media (max-width: 575px)`]: {
      width: "100%",
    },
  },
  bottomText: {
    marginTop: 25,
    color: "white",
  },
  icon: {},
}));
