import { getToken, onMessage } from "firebase/messaging";
import { messaging } from ".";
import { NotificationApi } from "../apis";

const VAPID_KEY = "BFqNvzW9mQg4zW_ClUPa-0MOYideF61P9d0m938JSE9dCe_Zwil7DOT0r0wnUW8nyxaIdnC60qO0Jrt3Ikzk3dI";

export const requestForToken = async () => {
  const token = await getToken(messaging, { vapidKey: VAPID_KEY });
  await NotificationApi.firebaseToken({ fcmToken: token });
};

export const onMessageListener = (callback: any) => {
  onMessage(messaging, (payload) => {
    callback(payload);
  });
};
