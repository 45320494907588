interface User {
  firstName?: string;
  lastName?: string;
}

export const renderName = (user: User = {}): string => {
  const lastInitial = user.lastName?.charAt(0) || "-";
  const firstName = user.firstName || "-";

  return lastInitial === "-" && firstName === "-" ? "-" : `${lastInitial}.${firstName}`;
};
