import { Image } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import Background from "../../../assets/bg-blur.webp";
import { OtpVerifyForm } from "../../../components/auth/otp-verify";
import Layout from "../../../components/layout/layout";

const EmailVerify = () => {
  const { classes } = useStyles();

  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      <div className={classes.container}>
        <OtpVerifyForm type="create" />
      </div>
    </Layout>
  );
};

export default EmailVerify;

const useStyles = createStyles(() => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.6,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 420,
    width: "100%",
    maxWidth: 624,
    borderRadius: 10,
    gap: 10,
    position: "relative",
  },
}));
