import { createStyles } from "@mantine/emotion";
import Breadcrumb from "../breadcrumb";

type MinilayoutProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  breadcrumb?: { to?: string; label: string }[];
  bgColor?: string;
};

const Minilayout = ({ title, subTitle, breadcrumb, bgColor = "white", extra = [], children }: MinilayoutProps) => {
  const { classes } = useStyles();
  return (
    <div
      className={classes.pageLayoutMini}
      style={{
        backgroundColor: bgColor,
      }}>
      {breadcrumb && <Breadcrumb data={breadcrumb} />}

      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>{extra}</div>
      </div>

      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Minilayout;

const useStyles = createStyles((theme) => ({
  pageLayoutMini: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: theme.spacing.md,
    backgroundColor: theme.white,
  },
  titleLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "block",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
  },
  subTitle: {
    fontSize: 14,
    margin: 0,
    padding: 0,
    color: theme.colors.gray[6],
  },
  extra: {
    display: "flex",
    gap: 15,
  },
}));
