import { Box, Center, Divider, Flex, Group, Modal, Paper, Text, UnstyledButton } from "@mantine/core";
import { IconCircleCheck, IconDots, IconLogin } from "@tabler/icons-react";
import Bogd from "../../../../assets/bogd.png";
import Golomt from "../../../../assets/golomt.svg";
import Dehub from "../../../../assets/logo-core.svg";

type FinancePermissionProps = {
  action: any;
  confirmation: boolean;
  setAction: React.Dispatch<React.SetStateAction<any[]>>;
  handleSuccess: () => void;
};

const FinancePermission = ({ action, confirmation, setAction, handleSuccess }: FinancePermissionProps) => {
  return (
    <Modal
      opened={action[0] === "finance"}
      onClose={() => setAction([])}
      centered
      title={false}
      size={"35rem"}
      withCloseButton={false}
      styles={{
        body: {
          padding: "2rem",
          margin: 0,
        },
        header: { background: "transparent" },
      }}>
      <div>
        {confirmation ? (
          <>
            <Box mb={"lg"} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <IconCircleCheck size={80} stroke={1.2} color="#4bb543" />
              <Text fz="lg" ta="center">
                Таны <strong>FINANCE</strong> эрх амжилттай үүслээ. Та өөрийн <strong>SUPPLIER</strong> болон <strong>BUYER</strong> бизнес эрхээрээ{" "}
                <strong>FINANCE</strong> модуль руу нэвтэрч орно уу.
              </Text>
            </Box>
            <UnstyledButton w="100%" onClick={() => handleSuccess()}>
              <Center sx={(theme) => ({ padding: theme.spacing.xs, backgroundColor: "#4bb543", borderRadius: theme.radius.sm })}>
                <Text c="white" fw={500} tt="uppercase">
                  Ойлголоо
                </Text>
              </Center>
            </UnstyledButton>
          </>
        ) : (
          <>
            <UnstyledButton
              w={"100%"}
              onClick={() => {
                window.open(`${action[1] && action[1].logins.find((item: any) => item.code === "BOGD").url}`);
                setAction([]);
              }}>
              <Paper withBorder radius={"md"}>
                <Group align="center" justify="space-between">
                  <Flex align="center" gap={"lg"}>
                    <Box
                      style={{
                        borderRadius: "5px 0 0 5px",
                        background: `linear-gradient(180deg, rgba(27,128,86,1) 0%, rgba(180,229,206,1) 100%)`,
                      }}
                      m={-1}
                      h={"80px"}
                      w={"6px"}></Box>
                    <Paper>
                      <img src={Bogd} alt="" height={"35px"} />
                    </Paper>
                  </Flex>
                  <Box pr={"lg"}>
                    <IconLogin />
                  </Box>
                </Group>
              </Paper>
            </UnstyledButton>
            <Center py={"xs"}>
              <Divider w={"120px"} mb={"5px"} />
            </Center>
            <UnstyledButton
              w={"100%"}
              p={0}
              onClick={() => {
                window.open(`${action[1] && action[1].logins.find((item: any) => item.code === "GOLOMT").url}`);
                setAction([]);
              }}>
              <Paper withBorder radius={"md"}>
                <Group align="center" justify="space-between">
                  <Flex align="center" gap={"lg"}>
                    <Box
                      style={{
                        borderRadius: "5px 0 0 5px",
                        background: `linear-gradient(180deg, rgba(249,77,162,1) 0%, rgba(171,102,253,1) 46%, rgba(117,197,214,1) 100%`,
                      }}
                      m={-1}
                      h={"80px"}
                      w={"6px"}></Box>
                    <Paper>
                      <img src={Golomt} alt="" height={"35px"} />
                    </Paper>
                  </Flex>
                  <Box pr={"lg"}>
                    <IconLogin />
                  </Box>
                </Group>
              </Paper>
            </UnstyledButton>
            <Center py={"xs"}>
              <Divider w={"120px"} mb={"5px"} />
            </Center>
            <UnstyledButton
              w={"100%"}
              p={0}
              onClick={() => {
                window.open(`${action[1] && action[1].logins.find((item: any) => item.code === "DEHUB").url}`);
                setAction([]);
              }}>
              <Paper withBorder radius={"md"}>
                <Group align="center" justify="space-between">
                  <Flex align="center" gap={"lg"}>
                    <Box
                      style={{
                        borderRadius: "5px 0 0 5px",
                        background: `linear-gradient(180deg, #114289 19%, rgba(102,255,51,1) 72%, rgba(133,209,245,1) 100%)`,
                      }}
                      m={-1}
                      h={"80px"}
                      w={"6px"}></Box>
                    <Flex align="center" gap="xs">
                      <Paper>
                        <img src={Dehub} alt="" height={"35px"} />
                      </Paper>
                      <div>
                        <Text fz={"lg"} fw={700} lh={"22px"}>
                          DeHUB
                        </Text>
                        <Text ta={"end"} fz={"xs"} c="dimmed" lh={"12px"}>
                          Finance general
                        </Text>
                      </div>
                    </Flex>
                  </Flex>
                  <Box pr={"lg"}>
                    <IconLogin />
                  </Box>
                </Group>
              </Paper>
            </UnstyledButton>
            <Center mt={"xs"}>
              <IconDots color="gray" stroke={1} />
            </Center>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FinancePermission;
