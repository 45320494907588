import { Box, Button, LoadingOverlay, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import * as yup from "yup";
import { OtpApi } from "../../apis";
import { Form } from "../../components/form";
import { IAuth } from "../../interfaces/IAuth";
import { authChange } from "../../store/auth";
import { message } from "../../utils/message";
import { OtpField } from "../form/otp-field";

type IForm = {
  otp: string | undefined;
};

const schema = yup.object({
  otp: yup
    .string()
    .matches(/^[0-9]{6}$/, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!"),
});

export function VerifyForm() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { accessToken, sessionScope } = useSelector((state: { auth: IAuth }) => state.auth);
  const dispatch = useDispatch();

  const { error } = useSWR<any>(
    "swr.otp.verify",
    async () => {
      try {
        const res = await OtpApi.get({ otpMethod: sessionScope || "REGISTER" });
        message.success(res?.message);
      } catch (error: any) {
        message.error(error.message);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const [data] = React.useState<IForm>({
    otp: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);

    try {
      const res = await OtpApi.verify({ otpMethod: sessionScope || "REGISTER", otpCode: values?.otp as string });

      dispatch(authChange(res));
      setLoading(false);
      message.success("Баталгаажуулалт амжилттай");
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <div className={classes.card}>
      {accessToken ? (
        <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
          {() => {
            return (
              <div className={classes.card}>
                <LoadingOverlay visible={loading} />
                <Text className={classes.title}>Баталгаажуулах код</Text>
                <Text ta="center" className={classes.bodyText}>
                  Таны бүртгэлтэй дугаар-руу явуулсан 6 оронтой тоог доорх талбарт оруулж мэдээллээ баталгаажуулна уу
                </Text>
                <OtpField numInputs={6} name="otp" size="md" />
                <Button
                  loading={loading}
                  type="submit"
                  variant="gradient"
                  radius={5}
                  size="md"
                  className={classes.button}
                  gradient={{ from: "#AD61FF", to: "#7165FA" }}>
                  Баталгаажуулах
                </Button>
              </div>
            );
          }}
        </Form>
      ) : (
        <>
          {error ? (
            <>
              <LoadingOverlay visible={loading} />
              <div className={classes.icon}></div>
              <Text c="red" className={classes.title}>
                Баталгаажуулахад алдаа гарлаа
              </Text>
              <Text ta="center" className={classes.bodyText}>
                Таны бүртгэлийг хийхэд алдаа гарлаа. Таньд илгээсэн баталгаажуулах холбоос зөвхөн нэг л удаа баталгаажуулахыг анхаарна уу
              </Text>
            </>
          ) : (
            <div className={classes.container}>
              <Box className={classes.card}>
                <Text ta="center" fw={500}>
                  Түр хүлээнэ үү...
                </Text>
              </Box>
            </div>
          )}
        </>
      )}
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    paddingTop: 80,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 420,
    maxWidth: 624,
    borderRadius: 10,
    gap: 20,
    position: "relative",
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 700,
    color: "black",
  },
  bodyText: {
    fontSize: 16,
    fontWeight: 400,
    color: "black",
  },
  input: {
    marginTop: 25,
    width: "60%",
  },
  button: {
    marginTop: 15,
  },
  bottomText: {
    marginTop: 25,
    color: "white",
  },
  icon: {},
}));
