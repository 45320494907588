import { createStyles } from "@mantine/emotion";
import Breadcrumb from "../breadcrumb";

type PageProps = {
  title: string | JSX.Element;
  subTitle?: string | JSX.Element;
  extra?: React.ReactNode[];
  children: React.ReactNode;
  breadcrumb?: { to?: string; label: string }[];
  bgColor?: string;
};

const PageLayout = ({ title, subTitle, breadcrumb, bgColor = "white", extra = [], children }: PageProps) => {
  const { classes } = useStyles();
  return (
    <div
      className={classes.pageLayout}
      style={{
        backgroundColor: bgColor,
      }}>
      {breadcrumb && <Breadcrumb data={breadcrumb} />}

      <div className={classes.titleLayout}>
        <div>
          <h2 className={classes.title}>{title}</h2>
          <p className={classes.subTitle}>{subTitle}</p>
        </div>
        <div className={classes.extra}>{extra}</div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default PageLayout;

const useStyles = createStyles((theme) => ({
  pageLayout: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    height: "calc(100vh - 70px)",
    marginTop: 70,
    padding: theme.spacing.xl,
    [`@media screen and (max-width: 575px)`]: {
      height: "auto",
    },
  },
  titleLayout: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "block",
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    margin: 0,
  },
  subTitle: {
    fontSize: 14,
    margin: 0,
    padding: 0,
    color: theme.colors.gray[6],
  },
  extra: {
    display: "flex",
    gap: 15,
  },
}));
