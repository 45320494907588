import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCqQJkhLcHC7Aeo_EjSqssl86gruvR-64k",
  authDomain: "dehub-ff4a0.firebaseapp.com",
  projectId: "dehub-ff4a0",
  storageBucket: "dehub-ff4a0.firebasestorage.app",
  messagingSenderId: "128939180308",
  appId: "1:128939180308:web:b6315b96f367dc1ae0c201",
  measurementId: "G-1VPVMH0EXF",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
