import { Grid } from "@mantine/core";
import { IconBuildingBank, IconFileInvoice, IconInbox, IconLayoutList, IconMoneybag, IconNetwork, IconUsers, IconWallet } from "@tabler/icons-react";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { GridCard } from "../../../../components/card";
import { IUser } from "../../../../interfaces/IUser";
import { dateTimeFormat } from "../../../../utils/date";
import { message } from "../../../../utils/message";

type ModuleProps = {
  user: IUser | null;
  accessToken: string | null;
  setAction: React.Dispatch<React.SetStateAction<any[]>>;
  setConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
};

let __WINDOW_IS_LOAD__ = false;

const Modules = ({ user, accessToken, setAction, setConfirmation }: ModuleProps) => {
  const location = useLocation();

  const memoizedData = useMemo(() => {
    return [
      {
        module: "PARTNER",
        name: "Partner",
        description: "Партнер профайл, Buyer болон Supplier бизнесийн тохиргоо",
        fromColor: "#32CD32",
        icon: IconBuildingBank,
        url: `https://partner.dehub.mn/access?token=${accessToken}`,
        // url: `http://localhost:3001/access?token=${accessToken}`,
        logins: [],
      },
      {
        module: "NETWORK",
        name: "Network",
        description: "Бусад бизнесийг урих, урилгаар хамтрах, нөхцөлөө тохирох",
        fromColor: "#A020F0",
        icon: IconNetwork,
        url: `https://network.dehub.mn/access?token=${accessToken}`,
        // url: `http://localhost:3002/access?token=${accessToken}`,
        logins: [],
      },
      {
        module: "INVOICE",
        name: "Invoice",
        description: "Нэхэмжлэх бичих, хүлээн авах, батлах, тооцоо нийлэх",
        fromColor: "#FFBF00",
        icon: IconFileInvoice,
        url: `https://invoice.dehub.mn/access?token=${accessToken}`,
        // url: `http://localhost:3001/access?token=${accessToken}`,
        logins: [],
      },
      {
        module: "INVENTORY",
        name: "Inventory",
        description: "Бараа, ажил үйлчилгээний бүртгэх, үнийн тохиргоо хийх",
        fromColor: "#7950F3",
        icon: IconInbox,
        url: `https://item.dehub.mn/access?token=${accessToken}`,
        // url: `http://localhost:3004/access?token=${accessToken}`,
      },
      {
        module: "USER",
        name: "User Management",
        description: "Системийн хэрэглэгч, АПП хэрэглэгчийн бүртгэл, эрх...",
        fromColor: "rgb(76, 110, 245)",
        icon: IconUsers,
        url: `https://user.dehub.mn/access?token=${accessToken}`,
        // url: `http://localhost:3001/access?token=${accessToken}`,
        logins: [],
      },
      {
        module: "PAYMENT",
        name: "Payment",
        description: "Бизнес хоорондын төлбөр тооцоо, НӨАТ гэх мэт",
        fromColor: "#00539C",
        icon: IconWallet,
        url: `https://payment.dehub.mn/access?token=${accessToken}`,
        // url: `http://localhost:3006/access?token=${accessToken}`,
        logins: [],
      },
      {
        module: "ORDER",
        name: "Order",
        description: "Борлуулалтын болон худалдан авалтын захиалга хүлээн авахаас хүлээлгэн өгөх хүртэлх процесс",
        fromColor: "#10A19D",
        icon: IconLayoutList,
        url: `https://order.dehub.mn/access?token=${accessToken}`,
        // url: `http://localhost:3001/access?token=${accessToken}`,
        logins: [],
      },
      {
        banks: [
          {
            isActive: false,
            name: "Golomt",
            url: `https://scf.golomtbank.com/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
              user?.currentBusiness?.refCode || "unknow"
            }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${
              user?.currentBusiness?.partner?.businessName || "unknow"
            }&&email=${user?.email || "unknow"}&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
          },
          {
            isActive: false,
            name: "Bogd",
            url: "",
          },
        ],
        module: "FINANCE",
        name: "Finance",
        description: "Батлагдсан нэхэмжлэхэд эрт санхүүжилт авах...",
        icon: IconMoneybag,
        url: `https://dehub.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
          user?.currentBusiness?.refCode || "unknow"
        }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${user?.currentBusiness?.partner?.businessName || "unknow"}&&email=${
          user?.email || "unknow"
        }&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
        logins: [
          {
            url: `https://bogd.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
              user?.currentBusiness?.refCode || "unknow"
            }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${
              user?.currentBusiness?.partner?.businessName || "unknow"
            }&&email=${user?.email || "unknow"}&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
            code: "BOGD",
          },
          {
            url: `https://scf.golomtbank.com/auth/login/?businessRef=${user?.currentBusiness?.refCode || "unknow"}&&regNumber=${
              user?.currentBusiness?.partner?.regNumber || "unknow"
            }&&finance=financeModule`,
            code: "GOLOMT",
          },
          {
            url: `https://dehub.scf.mn/auth/login/?businessName=${user?.currentBusiness?.profileName || "unknow"}&&businessRef=${
              user?.currentBusiness?.refCode || "unknow"
            }&&partnerRef=${user?.currentBusiness?.partner?.refCode || "unknow"}&&partnerName=${
              user?.currentBusiness?.partner?.businessName || "unknow"
            }&&email=${user?.email || "unknow"}&&regNumber=${user?.currentBusiness?.partner?.regNumber || "unknow"}&&finance=financeModule`,
            code: "DEHUB",
          },
        ],
      },
    ];
  }, [accessToken, user]);

  useEffect(() => {
    let authResult = new URLSearchParams(location.search);
    let verifyConfirmType = authResult.get("verifyConfirmType");

    if (verifyConfirmType && !__WINDOW_IS_LOAD__) {
      setAction(["finance", memoizedData[7]]);
      if (verifyConfirmType === "confirmed-golomt") {
        message.success(`Голомт банкны бүртгэл амжилттай баталгаажлаа. Та нэвтэрнэ үү. ${dateTimeFormat(new Date())}`);
        setConfirmation(true);
      } else if (verifyConfirmType === "confirmed-bogd") {
        message.success(`Богд банкны бүртгэл амжилттай баталгаажлаа. Та нэвтэрнэ үү. ${dateTimeFormat(new Date())}`);
        setConfirmation(true);
      }
      __WINDOW_IS_LOAD__ = true;
    }
  }, [location, memoizedData, setAction, setConfirmation]);

  return (
    <Grid gutter={20}>
      {memoizedData.map((data, index: number) => (
        <GridCard data={data} setAction={setAction} key={index} />
      ))}
    </Grid>
  );
};

export default Modules;
