import { Box, Image, LoadingOverlay } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import useSWR from "swr";
import { AuthApi } from "../../../apis";
import Background from "../../../assets/bg-blur.webp";
import { CreatePassword } from "../../../components/auth/create-password";
import { PhoneVerifyCheckForm } from "../../../components/auth/phone-verify-check";
import { VerifyForm } from "../../../components/auth/verify";
import Layout from "../../../components/layout/layout";
import { IAuth } from "../../../interfaces/IAuth";
import { authChange } from "../../../store/auth";
import { message } from "../../../utils/message";

export function Verify() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(true);
  const { sessionScope } = useSelector((state: { auth: IAuth }) => state.auth);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { isLoading } = useSWR<any>(
    "swr.auth.verify",
    async () => {
      setLoading(true);
      try {
        if (id !== "phone") {
          const res = await AuthApi.verify(id as string);
          if (res?.isClicked) {
            dispatch(authChange({ ...res, sessionScope: "CHECK_PHONE" } as any));
          } else dispatch(authChange(res as any));
        }
        setLoading(false);
      } catch (error: any) {
        message.error(error?.message || "Хүсэлтийг биелүүлэхэд алдаа гарлаа");
        setHasError(true);
        setLoading(false);
      }
    },
    {
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  const render = (sessionScope: any) => {
    switch (sessionScope) {
      case "CHECK_PHONE":
        return (
          <div className={classes.container}>
            <PhoneVerifyCheckForm />
          </div>
        );
      case "FORGOT":
        return (
          <div className={classes.container}>
            <VerifyForm />
          </div>
        );
      case "CHANGE_PASSWORD":
        return (
          <div className={classes.container}>
            <CreatePassword />
          </div>
        );
    }
  };

  if (loading || isLoading) return <LoadingOverlay visible />;
  else
    return (
      <Layout>
        <Image className={classes.background} src={Background} />
        {sessionScope && render(sessionScope)}
        {hasError && (
          <div className={classes.container}>
            <Box className={classes.card}>Хүсэлтийг баталгаажуулахад алдаа гарлаа</Box>
          </div>
        )}
      </Layout>
    );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    [`@media screen and (max-width: 575px)`]: {
      width: "90%",
    },
  },
  card: {
    padding: theme.spacing.xl,
    backgroundColor: "#fff",
    borderRadius: theme.radius.lg,
    width: 520,
    textAlign: "center",
    [`@media (max-width: 519px)`]: {
      width: "100%",
    },
  },
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
}));
