import { Box, Grid, Image } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import Background from "../../../assets/background-blur.svg";
import BackgroundLine from "../../../assets/background-line.svg";
import LoginImage from "../../../assets/login-svg.webp";
import Layout from "../../../components/layout/layout";
import LoginForm from "../../../components/loginForm";
import { useScreenDetector } from "../../../components/screen-detector";

const Login = () => {
  const { classes } = useStyles();
  const { isTablet } = useScreenDetector();

  return (
    <Layout>
      <Image className={classes.background} src={Background} draggable={false} />
      <Image className={classes.backgroundLine} src={BackgroundLine} draggable={false} />
      <Grid className={classes.container}>
        {!isTablet && (
          <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
            <Box className={classes.image}>
              <img src={LoginImage} alt={"svg"} style={{ height: "100%", width: "100%", objectFit: "contain" }} draggable={false} />
            </Box>
          </Grid.Col>
        )}
        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
          <Box className={classes.card}>
            <div className={classes.loginText}>НЭВТРЭХ</div>
            <LoginForm />
          </Box>
        </Grid.Col>
      </Grid>
    </Layout>
  );
};

export default Login;

const useStyles = createStyles((theme) => ({
  container: {
    height: "100%",
    maxWidth: "1440px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media screen and (max-width: 575px)`]: {
      padding: "1rem",
      maxWidth: "100%",
      height: "auto",
      paddingTop: 150,
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff15",
    maxWidth: "80%",
    margin: "auto",
    padding: theme.spacing.xl,
    borderRadius: theme.radius.sm,
    position: "relative",
  },
  image: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginText: {
    fontSize: 40,
    color: theme.white,
    fontWeight: 700,
  },
  background: {
    position: "absolute",
    opacity: 0.4,
    bottom: 0,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
  },
}));
