import { Box, Grid, Image, LoadingOverlay, MantineTheme } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import Background from "../../../assets/background-blur.svg";
import BackgroundLine from "../../../assets/background-line.svg";
import LoginImage from "../../../assets/login-svg.webp";
import ForgotForm from "../../../components/forgotForm";
import Layout from "../../../components/layout/layout";
import { useScreenDetector } from "../../../components/screen-detector";

const ForgotPassword = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { isTablet } = useScreenDetector();

  return (
    <Layout>
      <Image className={classes.background} src={Background} draggable={false} />
      <Image className={classes.backgroundLine} src={BackgroundLine} draggable={false} />
      <Grid className={classes.container}>
        {!isTablet && (
          <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
            <Box className={classes.image}>
              <Image src={LoginImage} alt={"svg"} style={{ width: "100%", height: "100%", objectFit: "contain" }} draggable={false} />
            </Box>
          </Grid.Col>
        )}
        <Grid.Col span={{ sm: 12, md: 6, lg: 6 }}>
          <Box className={classes.card}>
            <div className={classes.loginText}>НУУЦ ҮГ СЭРГЭЭХ</div>
            <ForgotForm setLoading={setLoading} />
            <LoadingOverlay visible={loading} />
          </Box>
        </Grid.Col>
      </Grid>
    </Layout>
  );
};

const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    height: "100%",
    maxWidth: "1440px",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [`@media screen and (max-width: 575px)`]: {
      padding: "1rem",
      maxWidth: "100%",
      height: "auto",
      paddingTop: 150,
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff15",
    maxWidth: "80%",
    margin: "auto",
    padding: theme.spacing.xl,
    borderRadius: theme.radius.sm,
    position: "relative",
  },
  image: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginText: {
    fontSize: 40,
    color: theme.white,
    fontWeight: 700,
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
  },
}));

export default ForgotPassword;
