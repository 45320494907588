import { LoadingOverlay } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { AuthApi } from "./apis";
import { onMessageListener } from "./firebase/fcm-service";
import { IAuth } from "./interfaces/IAuth";
import EmailVerify from "./pages/auth/email-verify";
import ForgotPassword from "./pages/auth/forgot";
import Login from "./pages/auth/login";
import { Verify } from "./pages/auth/verify";
import HomePage from "./pages/main/index";
import Profile from "./pages/profile";
import { authMe } from "./store/auth";

let __WINDOW_LOADED__ = false;

export function App() {
  const [loading, setLoading] = useState(false);
  const { accessToken, sessionScope } = useSelector((state: { auth: IAuth }) => state.auth);

  const ConditionalAccess = ({ children }: any) => {
    const [searchParams] = useSearchParams();

    const hasQueryParams = Array.from(searchParams.keys()).length > 0;

    if (hasQueryParams) {
      return children;
    }

    if (accessToken && sessionScope === "AUTHORIZED") {
      return children;
    }

    return <Navigate to="/login" />;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onMessageListener((payload: any) => {
      showNotification({
        id: `notif-${Math.random()}`,
        title: payload?.notification?.title || "New Notification",
        message: payload?.notification?.body || "You have received a new notification.",
        color: "blue",
        autoClose: 5000,
      });
    });

    return typeof unsubscribe === "function" ? unsubscribe : undefined;
  }, []);

  const init = useCallback(async () => {
    setLoading(true);
    try {
      if (accessToken) {
        const auth = await AuthApi.me();
        dispatch(authMe(auth));
      }
    } catch (error: any) {
      console.error("Failed to fetch auth data:", error?.message);
    } finally {
      setLoading(false);
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (!__WINDOW_LOADED__) init();
    __WINDOW_LOADED__ = true;
  }, [init, accessToken]);

  if (loading && !__WINDOW_LOADED__) return <LoadingOverlay visible={loading} />;

  return (
    <Routes>
      <Route>
        <Route
          path="/auth/profile/dan"
          element={
            <ConditionalAccess>
              <Profile />
            </ConditionalAccess>
          }
        />
        {accessToken && sessionScope === "AUTHORIZED" ? (
          <>
            <Route path="/" element={<HomePage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot" element={<ForgotPassword />} />
            <Route path="/verify/:id" element={<Verify />} />
            <Route path="/registration/:id" element={<EmailVerify />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Route>
    </Routes>
  );
}
