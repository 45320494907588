import { Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

type BreadcrumbProps = {
  data: { label: string; to?: string }[];
};

const Breadcrumb = ({ data }: BreadcrumbProps) => {
  return (
    <Breadcrumbs separator="/">
      {data.map((item, index) => (
        <Fragment key={index}>{item.to ? <Link to={item.to}>{item.label}</Link> : <span>{item.label}</span>}</Fragment>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
