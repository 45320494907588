import { Button, Divider, Flex, LoadingOverlay } from "@mantine/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AuthApi } from "../../apis";
import { authMe } from "../../store/auth";
import { message } from "../../utils/message";
import { Form } from "../form";
import { PasswordField } from "../form/password-field";

const schema = (hasPin: boolean) => {
  return yup.object({
    oldPin: hasPin
      ? yup
          .string()
          .matches(/^[0-9]{6}$/, "Пин код 6 оронтой тоо байна")
          .required("Хуучин пин код заавал бөглөх")
      : yup.string().nullable(),
    pin: yup
      .string()
      .min(6, "6 тэмдэгттэй байх ёстой")
      .max(6, "6 тэмдэгттэй байх ёстой")
      .matches(/^[0-9]{6}$/, "Пин код 6 оронтой тоо байна")
      .required("Заавал бөглөнө!"),
    checkPin: yup
      .string()
      .matches(/^[0-9]{6}$/, "Пин код 6 оронтой тоо байна")
      .required("Заавал бөглөнө!")
      .oneOf([yup.ref("pin")], "Пин код таарахгүй байна"),
  });
};
type IForm = {
  oldPin: undefined | string;
  pin: undefined | string;
  checkPin: undefined | string;
};

export function ChangePin({ onClose }: { onClose: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const [data] = React.useState<IForm>({
    oldPin: undefined,
    pin: undefined,
    checkPin: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      if (user && user?.hasPin) {
        await AuthApi.updatePin({ oldPin: values.oldPin, pin: values.pin });
        setLoading(false);
        message.success("Пин код амжилттай солигдлоо.");
        onClose();
      } else {
        await AuthApi.createPin({ pin: values.pin });
        setLoading(false);
        const auth = await AuthApi.me();
        dispatch(authMe(auth));
        message.success("Пин код амжилттай үүслээ.");
        onClose();
      }
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message || "Хүсэлт амжилтгүй боллоо!");
    }
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema(user && user?.hasPin)}>
      {() => {
        return (
          <Flex direction="column" gap="md">
            <LoadingOverlay visible={loading} />
            {user && user?.hasPin && <PasswordField name="oldPin" label="Хуучин пин код" placeholder="Хуучин пин код" />}
            <PasswordField name="pin" label="Шинэ пин код" placeholder="Шинэ пин код" />
            <PasswordField name="checkPin" label="Пин код давтах" placeholder="Пин код давтах" />
            <Divider />
            <Flex justify="space-between" align="center">
              <Button onClick={() => onClose()} variant="outline" color="gray">
                Болих
              </Button>
              <Button type="submit" variant="filled">
                Хадгалах
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
