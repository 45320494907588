import { Box, Button, Checkbox, Flex, Image, Modal, Text } from "@mantine/core";
import { useEffect, useState } from "react";

type GuideProps = {
  open: boolean;
  onClose: () => void;
};

const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setDate(expires.getDate() + days);
  document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; path=/`;
};

const getCookie = (name: string) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
};

const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const Guide = ({ open, onClose }: GuideProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasChecked, setHasChecked] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    if (hasChecked) return;

    const guideDismissed = getCookie("guideDismissed") === "true";
    const notificationsDisabled = getCookie("notificationsDisabled") === "true";
    const permission = Notification.permission;

    if (permission === "granted") {
      deleteCookie("notificationsDisabled");
      deleteCookie("guideDismissed");
      setIsModalOpen(false);
    } else if (!guideDismissed && !notificationsDisabled) {
      setIsModalOpen(true);
    }

    setHasChecked(true);
  }, [hasChecked]);

  const handleClose = () => {
    if (dontShowAgain) {
      setCookie("guideDismissed", "true", 365);
    }
    setIsModalOpen(false);
    onClose();
  };

  const handleAllowNotifications = async () => {
    try {
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        new Notification("Та мэдэгдэл хүлээн авахыг зөвшөөрсөн!");
        localStorage.removeItem("notificationsDenied");
        deleteCookie("notificationsDisabled");
        deleteCookie("guideDismissed");
        window.location.reload();
      } else {
        localStorage.setItem("notificationsDenied", "true");
      }

      handleClose();
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  };

  const denyAllowNotifications = () => {
    setCookie("notificationsDisabled", "true", 365);
    handleClose();
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDontShowAgain(event.currentTarget.checked);
  };

  return (
    <Modal title="Мэдэгдэл идэвхжүүлэх заавар" opened={isModalOpen} onClose={onClose} size={900} centered withinPortal>
      <Box>
        <Text ta="center" mb={20}>
          Таны хөтөч мэдэгдэл авах эрх тохируулаагүй тул та доорх зааврын дагуу эрхийн тохиргоо хийнэ үү.
        </Text>
        <Box style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "center" }} mb={20}>
          <Text>
            1. Таны хөтөч хуудас ачаалж дууссаны дараа хөтчийн зүүн дээд булангаас асуух цонх гарахад та{" "}
            <Text component="span" fw={600} c="red">
              Allow
            </Text>{" "}
            - г дарсанаар мэдэгдэл авах тохиргоог идэвхжүүлнэ.
          </Text>
          <Image src={"/zaavar.png"} style={{ width: 300 }} />
        </Box>
        <Box style={{ display: "flex", flexDirection: "column", gap: 20, alignItems: "center" }} mb={20}>
          <Text>
            2. Хэрвээ гарч ирээгүй тохиолдолд хайлтын хэсгийн зүүн буланд байрлах хуудасны тохиргооны цэсийг дарснаар мэдэгдэл авах тохиргоог идэвхжүүлнэ.
          </Text>
          <Image src={"/zaavar_menu.png"} style={{ width: 300 }} />
        </Box>
        <Flex justify="space-between" align="center">
          <Checkbox label="Дахиж харахгүй" checked={dontShowAgain} onChange={handleCheckboxChange} />
          <Flex gap="sm">
            <Button color="green.5" onClick={handleAllowNotifications}>
              Ойлголоо
            </Button>
            <Button color="red.5" onClick={denyAllowNotifications}>
              Үгүй ээ, би мэдэгдэл авахгүй
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};

export default Guide;
