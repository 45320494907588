/* eslint-disable react-hooks/exhaustive-deps */
import { Image, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthApi } from "../../apis";
import Background from "../../assets/background-blur.svg";
import BackgroundLine from "../../assets/background-line.svg";
import Layout from "../../components/layout/layout";
import Guide from "../../components/notifications/guide";
import { requestForToken } from "../../firebase/fcm-service";
import { IAuth } from "../../interfaces/IAuth";
import { authChange, authMe } from "../../store/auth";
import { message } from "../../utils/message";
import FinancePermission from "./components/finance-permission";
import Modules from "./components/modules";

const HomePage = () => {
  const { classes } = useStyles();
  const { user, accessToken } = useSelector((state: { auth: IAuth }) => state.auth);
  const [action, setAction] = useState<any[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [permission, setPermission] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user && !accessToken) {
      window.location.href = "/login";
    }
  }, [accessToken, user]);

  const handleSuccess = async () => {
    try {
      await AuthApi.logout();
      const removeToken = { accessToken: null };
      const removeUser = {};
      dispatch(authChange(removeToken as any));
      dispatch(authMe(removeUser as any));
      setConfirmation(false);
    } catch (error: any) {
      message.error(error?.message);
    }
  };

  const hasRequestedToken = useRef(false);

  const getPermission = async () => {
    const permission = await Notification.requestPermission();
    setPermission(permission);
  };

  useEffect(() => {
    getPermission();
  }, []);

  useEffect(() => {
    if (!hasRequestedToken.current && accessToken) {
      if (permission === "granted") {
        requestForToken();
        hasRequestedToken.current = true;
        setOpen(false);
      } else {
        setOpen(true);
      }
    }
  }, [accessToken, permission]);

  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      <Image className={classes.backgroundLine} src={BackgroundLine} />
      <div className={classes.container}>
        <div className={classes.row}>
          <div>
            <Text fz={"lg"} c={"white"}>
              Сайн байна уу!
            </Text>
            <div className={classes.text}>
              {user?.lastName ? user?.lastName[0] + "." : ""}
              {user?.firstName ?? "Админ"}
            </div>
            <div className={classes.description}>Бизнес хоорондын үнэ цэн нэмсэн түншлэлийн экосистемд тавтай морил!</div>
          </div>
        </div>
        <Modules user={user} accessToken={accessToken} setAction={setAction} setConfirmation={setConfirmation} />
      </div>
      <FinancePermission setAction={setAction} handleSuccess={handleSuccess} action={action} confirmation={confirmation} />
      <Guide open={open} onClose={() => setOpen(false)} />
    </Layout>
  );
};

const useStyles = createStyles(() => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 120,
    paddingBottom: 120,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  background: {
    position: "absolute",
    opacity: 0.5,
    bottom: 0,
    zIndex: -1,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  description: {
    maxWidth: "1000px",
    fontSize: 18,
    color: "white",
  },
  text: {
    fontSize: 64,
    color: "white",
    fontWeight: 800,
    textTransform: "capitalize",
  },
}));

export default HomePage;
