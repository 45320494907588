import { Button, LoadingOverlay, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconPhone } from "@tabler/icons-react";
import React, { useState } from "react";
import * as yup from "yup";
import { OtpApi } from "../../apis";
import { Form } from "../../components/form";
import { TextField } from "../../components/form/text-field";
import { message } from "../../utils/message";
import { OtpVerifyForm } from "./otp-verify";

type IForm = {
  phone: string | any;
};

const schema = yup.object({
  phone: yup
    .number()
    .min(10000000, "Утасны дугаар зөв оруулна уу.")
    .max(99999999, "Утасны дугаар зөв оруулна уу.")
    .typeError("Утасны дугаар зөв оруулна уу.")
    .required("Заавал бөглөнө!"),
});

export function PhoneVerifyCheckForm() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isCheck, setIsCheck] = useState(false);

  const [data] = React.useState<IForm>({
    phone: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);

    try {
      const res = await OtpApi.phoneCheck({ otpMethod: "FORGOT", phone: values?.phone as string });
      setIsCheck(true);
      setLoading(false);
      message.success(res?.message || "Баталгаажуулалт амжилттай");
    } catch (error: any) {
      message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <>
      {!isCheck ? (
        <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
          {() => {
            return (
              <Paper className={classes.card} px={"lg"}>
                <LoadingOverlay visible={loading} />
                <Text c="#0D1955" ta="center" w={"500px"} className={classes.title}>
                  Бүртгэлтэй утасны дугаараа <br /> оруулна уу.
                </Text>
                <TextField icon={<IconPhone size={18} />} name="phone" radius={5} size="md" placeholder="Утасны дугаар оруулах" />
                <Button loading={loading} type="submit" variant="gradient" radius={5} size="md" gradient={{ from: "#AD61FF", to: "#7165FA" }}>
                  Код авах
                </Button>
              </Paper>
            );
          }}
        </Form>
      ) : (
        <OtpVerifyForm type="phone" />
      )}
    </>
  );
}

const useStyles = createStyles((theme) => ({
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    padding: theme.spacing.xl,
    backgroundColor: "#fff",
    borderRadius: theme.radius.lg,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 520,
    gap: theme.spacing.md,
    [`@media (max-width: 575px)`]: {
      width: "100%",
    },
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    wordWrap: "break-word",
  },
}));
